import { Shipment } from '@xpo-ltl/sdk-disputes';
import { InspectionShipment } from '@xpo-ltl/sdk-inspections';

interface RowShipmentIndicator {
  error?: boolean;
  possibleDuplicate?: boolean;
  ineligible?: boolean;
  ineligibleReasons?: string;
}
export interface RowShipmentErrors {
  notFound?: boolean;
  needBeFixed?: boolean;
  relatedClaimNotFound?: boolean;
  relatedClaimNotApproved?: boolean;
  relatedClaimWithPendingRebuttal?: boolean;
  multipleRelatedClaims?: boolean;
}
export interface InspectorContext {
  inspectionSic?: string;
  inspectorEmployeeId?: string;
  inspectorFirstNm?: string;
  inspectorLastNm?: string;
}
export class RowShipment extends Shipment {
  // NOTE: every field you add, you sould remove when you send to API
  idRow: number;
  selected: boolean;
  indicators?: RowShipmentIndicator;
  errors?: RowShipmentErrors;
  requestedAdjustmentDeltaAmountFormatted: string;
  inspector?: InspectorContext;
  previousInspectionDetails?: InspectionShipment[];
  included?: boolean;

  constructor() {
    super();
    this.indicators = {};
  }
}
