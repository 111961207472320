import { NgModule } from '@angular/core';

import { LocationApiService } from '@xpo-ltl-2.0/sdk-location';
import { AccountsReceivableApiService } from '@xpo-ltl/sdk-accountsreceivable';
import { PricingApiService } from '@xpo-ltl/sdk-pricing';
import { ShipmentOdsApiService } from '@xpo-ltl/sdk-shipmentods';
import { XpoLtlShipmentDetailsModule } from '@xpo-ltl/ngx-ltl';

import { NotesEmailsModule } from '../../../../lib/src/public_api';
import { DisputesDialogService } from '../../services/disputes/disputes-dialog.service';
import { ReassignService } from '../../services/disputes/reassign.service';
import { SharedModule } from '../../shared.module';
import { BaseDisputeDialogComponent } from './components/base-dispute-dialog/base-dispute-dialog.component';
import { ClaimsDisputeDialogRowComponent } from './components/claims-dispute-dialog-row';
import { ConfirmNewClaimsDisputeDialogComponent } from './components/confirm-new-claims-dispute-dialog';
import { ConfirmNewDisputeDialogComponent } from './components/confirm-new-dispute-dialog';
import { ContactsListDialogComponent } from './components/contacts-list-dialog';
import { DisputeRouteGuardDialogComponent } from './components/dispute-route-guard-dialog/dispute-route-guard-dialog.component';
import { DisputesExportDialogComponent } from './components/disputes-export-dialog/disputes-export-dialog.component';
import { DisputesHistoryDialogComponent } from './components/disputes-history-dialog/disputes-history-dialog.component';
import { EmailDialogComponent } from './components/email-dialog';
import { DisputeExplanationDialogComponent } from './components/explanation-dialog';
import { RelatedDisputesComponent } from './components/invoice-details/related-disputes/related-disputes.component';
import { MatchDialogComponent } from './components/match-dialog/match-dialog.component';
import { ProDownloadTemplateDialogComponent } from './components/pro-download-template-dialog/pro-download-template-dialog.component';
import { ReassignDialogComponent } from './components/reassign-dialog/reassign-dialog.component';
import { SelectEmployeeDialogComponent } from './components/select-employee-dialog/select-employee-dialog.component';
import { SelectProDialogComponent } from './components/select-pro-dialog/select-pro-dialog.component';
import { SuccessDisputeDialogComponent } from './components/success-dispute-dialog';
import { InspectorHistoryDialogComponent } from './components/inspector-history-dialog/inspector-history-dialog.component';
import { UploadProConfirmationDialogComponent } from './components/upload-pro-confirmation-dialog/upload-pro-confirmation-dialog.component';
import { SelectShipperDialogComponent } from './components/select-shipper-dialog/select-shipper-dialog.component';

@NgModule({
  declarations: [
    BaseDisputeDialogComponent,
    ConfirmNewDisputeDialogComponent,
    ConfirmNewClaimsDisputeDialogComponent,
    ClaimsDisputeDialogRowComponent,
    ContactsListDialogComponent,
    DisputeExplanationDialogComponent,
    EmailDialogComponent,
    MatchDialogComponent,
    ReassignDialogComponent,
    SelectEmployeeDialogComponent,
    SelectShipperDialogComponent,
    InspectorHistoryDialogComponent,
    SelectProDialogComponent,
    SuccessDisputeDialogComponent,
    ProDownloadTemplateDialogComponent,
    DisputesHistoryDialogComponent,
    DisputesExportDialogComponent,
    DisputeRouteGuardDialogComponent,
    RelatedDisputesComponent,
    UploadProConfirmationDialogComponent
  ],
  imports: [
    SharedModule, NotesEmailsModule,
    XpoLtlShipmentDetailsModule
  ],
  entryComponents: [
    SelectProDialogComponent,
    SelectEmployeeDialogComponent,
    SuccessDisputeDialogComponent,
    ConfirmNewDisputeDialogComponent,
    ConfirmNewClaimsDisputeDialogComponent,
    ClaimsDisputeDialogRowComponent,
    MatchDialogComponent,
    DisputeExplanationDialogComponent,
    EmailDialogComponent,
    ContactsListDialogComponent,
    ReassignDialogComponent,
    ProDownloadTemplateDialogComponent,
    DisputesHistoryDialogComponent,
    DisputesExportDialogComponent,
    DisputeRouteGuardDialogComponent,
    RelatedDisputesComponent,
    UploadProConfirmationDialogComponent
  ],
  providers: [
    DisputesDialogService,
    ReassignService,
    ShipmentOdsApiService,
    PricingApiService,
    LocationApiService,
    AccountsReceivableApiService,
  ],
})
export class DisputesDialogModule {}
