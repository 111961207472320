<div class="select-employee-dialog">
  <h1 mat-dialog-title class="select-employee-dialog__title">
    {{ currentStep === 1 ? title : 'Select Inspector' }}
    <xpo-dialog-close-button></xpo-dialog-close-button>
  </h1>
  <div select-employee-dialog__content mat-dialog-content>
    <div class="error-text">
      <mat-icon>warning</mat-icon>
      <p>
        All the selected PROs must have the same shipper and inspector.
      </p>
    </div>
    <div class="info-text">
      <mat-icon>info</mat-icon>
      <p>
        PROs of the shipper(s) not selected will be will be removed from the dispute but will be visible in the Excluded
        tab.
      </p>
    </div>

    <ng-container *ngIf="currentStep === 1">
      <div class="form-controls">
        <div class="form-fields">
          <div *ngFor="let row of shippers" class="width-50" floatLabel="always" data-test="lastName">
            <mat-radio-button [value]="row[0]" (change)="shipperSelected(row)"></mat-radio-button>
            <mat-label>{{ row[0] }}</mat-label>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="currentStep === 2">
      <div class="form-controls">
        <div class="form-fields">
          <div *ngFor="let row of inspectors" class="width-50" floatLabel="always" data-test="lastName">
            <mat-radio-button [value]="row.inspectorFirstNm" (change)="inspectorSelected(row)"></mat-radio-button>
            <mat-label
              >{{ generateInspectorName(row[1].value) }} <strong>{{ row[1].counter }}</strong> of
              {{ totalPROs }} uploaded PROs</mat-label
            >
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div mat-dialog-actions class="select-employee-dialog__footer">
    <button
      *ngIf="currentStep === 2"
      mat-flat-button
      xpoSmallButton
      xpoButton
      class="button-select"
      (click)="onSelectClick()"
      [disabled]="!selectedShipper"
      data-test="select"
    >
      SELECT
    </button>
    <button
      *ngIf="currentStep === 1"
      mat-flat-button
      xpoSmallButton
      xpoButton
      class="button-select"
      (click)="onSelectShipper()"
      [disabled]="!selectedShipper"
      data-test="select"
    >
      PROCEED
    </button>
  </div>
</div>
