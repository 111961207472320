<div [formGroup]="formGroup" class="select-employee-dialog">
  <h1 mat-dialog-title class="select-employee-dialog__title">
    {{ title }}
    <xpo-dialog-close-button></xpo-dialog-close-button>
  </h1>
  <div select-employee-dialog__content mat-dialog-content>
    <mat-tab-group #tabGroup [hidden]="!isWNI" >
      <mat-tab>
        <ng-template mat-tab-label>
          Suggested
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          Manual Selection
        </ng-template>
      </mat-tab>
    </mat-tab-group>

    <div [hidden]="!(isSuggestedSelectionActive$ | async)" class="suggested-description">
      <div class="suggested-description-text">
        <mat-icon>info</mat-icon>
        <p>
          PROs of the shipper(s) not selected will be will be removed from the dispute but will be visible in the Excluded tab.
        </p>
      </div>
      <div class="form-controls">
        <div class="form-fields">
          <div *ngFor="let row of inspectors"class="width-50" floatLabel="always" data-test="lastName">
            <mat-radio-button [value]="row.inspectorFirstNm" (change)="inspectorSelected(row)"></mat-radio-button>
            <mat-label>{{ generateInspectorName(row[1].value) }}  <strong>{{row[1].counter}}</strong> of {{ totalPROs}} uploaded PROs</mat-label>
          </div>
        </div>
      </div>
    </div>
    <div [hidden]="!(isManualSelectionActive$ | async)">
      <div class="description">
        <p>
          Enter the XPO internal employee’s last name first. Select one option from the list to add it to the dispute.
        </p>
      </div>

      <div class="form-controls">
        <div class="form-fields">
          <mat-form-field class="width-50" floatLabel="always" data-test="lastName">
            <mat-label>{{ SelectEmployeeDialogFormLabelsEnum.LAST_NAME }}</mat-label>
            <input
              #lastName
              matInput
              (keydown)="onKeyDown($event)"
              [formControlName]="SelectEmployeeDialogFormNamesEnum.LAST_NAME"
              required
            />
          </mat-form-field>
          <mat-form-field class="width-50" floatLabel="always" data-test="firstName">
            <mat-label>{{ SelectEmployeeDialogFormLabelsEnum.FIRST_NAME }}</mat-label>
            <input
              matInput
              (keydown)="onKeyDown($event)"
              [formControlName]="SelectEmployeeDialogFormNamesEnum.FIRST_NAME"
            />
          </mat-form-field>
        </div>
        <button
          xpoSmallButton xpoButton
          class="button-search"
          mat-stroked-button
          (click)="onSearchClick()"
          [disabled]="!isValidForm"
          data-test="search"
        >
          {{ SelectEmployeeDialogFormLabelsEnum.SEARCH }}
        </button>
      </div>

      <div class="feedback">
        <div *ngIf="loading">Loading ...</div>
        <div *ngIf="!dataSource?.data.length && employeesNotFound && !loading" data-test="error-message">
          {{ messageError }}
        </div>
      </div>

      <div class="table-box" [hidden]="loading || !(dataSource?.data.length > 0)">
        <div class="select-employee-dialog__table_container">
          <table mat-table matSort [dataSource]="dataSource" class="select-employee-dialog__table">
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let row">
                <mat-radio-button (change)="selectEmployee(row)" data-test="selectEmployee"></mat-radio-button>
              </td>
            </ng-container>
            <ng-container matColumnDef="lastName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ SelectEmployeeDialogFormLabelsEnum.LAST_NAME }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.lastName }}</td>
            </ng-container>
            <ng-container matColumnDef="firstName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ SelectEmployeeDialogFormLabelsEnum.FIRST_NAME }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.firstName }}</td>
            </ng-container>
            <ng-container matColumnDef="jobTitle">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ SelectEmployeeDialogFormLabelsEnum.JOB_TITLE }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.jobTitle }}</td>
            </ng-container>
            <ng-container matColumnDef="jobCode">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ SelectEmployeeDialogFormLabelsEnum.JOB_CODE }}</th>
              <td mat-cell *matCellDef="let row">{{ row.jobCode }}</td>
            </ng-container>
            <ng-container matColumnDef="sicCode">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ SelectEmployeeDialogFormLabelsEnum.SIC_CODE }}</th>
              <td mat-cell *matCellDef="let row">{{ row.sicCode }}</td>
            </ng-container>
            <ng-container matColumnDef="locationSicName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ SelectEmployeeDialogFormLabelsEnum.LOCATION_SIC_NAME }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.locationSicName }}</td>
            </ng-container>
            <ng-container matColumnDef="sfEmployeeId">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ SelectEmployeeDialogFormLabelsEnum.SF_EMPLOYEE_ID }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.sfEmployeeId }}</td>
            </ng-container>
            <ng-container matColumnDef="address1">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ SelectEmployeeDialogFormLabelsEnum.ADDRESS_1 }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.address1 }}</td>
            </ng-container>
            <ng-container matColumnDef="cityName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ SelectEmployeeDialogFormLabelsEnum.CITY_NAME }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.cityName }}</td>
            </ng-container>
            <ng-container matColumnDef="stateCd">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ SelectEmployeeDialogFormLabelsEnum.STATE_CD }}</th>
              <td mat-cell *matCellDef="let row">{{ row.stateCd }}</td>
            </ng-container>
            <ng-container matColumnDef="countryCd">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ SelectEmployeeDialogFormLabelsEnum.COUNTRY_CODE }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.countryCd }}</td>
            </ng-container>
            <ng-container matColumnDef="zip">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ SelectEmployeeDialogFormLabelsEnum.ZIP }}</th>
              <td mat-cell *matCellDef="let row">{{ row.zip }}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
        <p class="total-result" data-test="resultCount">
          Showing {{ dataSource?.data.length }} out of {{ dataSource?.data.length }} Results
        </p>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="select-employee-dialog__footer">
    <button mat-button (click)="onCancelClick()" data-test="cancel">
      {{ SelectEmployeeDialogFormLabelsEnum.CANCEL }}
    </button>
    <button
      mat-flat-button
      xpoSmallButton xpoButton
      class="button-select"
      (click)="onSelectClick()"
      [disabled]="!selectedEmployee"
      data-test="select"
    >
      {{ SelectEmployeeDialogFormLabelsEnum.SELECT }}
    </button>
  </div>
</div>
