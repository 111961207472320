import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { SelectEmployeeDialogInterface } from 'src/app/interfaces/disputes/select-employee-dialog.interface';

@Component({
  selector: 'app-select-shipper-dialog',
  templateUrl: './select-shipper-dialog.component.html',
  styleUrls: ['./select-shipper-dialog.component.scss'],
})
export class SelectShipperDialogComponent implements OnInit, OnDestroy {
  title: string;
  unsubscriber = new Unsubscriber();
  messageError: string;
  selectedShipper: string;
  selectedEmployee: SelectEmployeeDialogInterface = undefined;;
  shippers = [];
  inspectors = [];
  currentStep = 1;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<SelectShipperDialogComponent>,
  ) {
    this.title = this.data.hasOwnProperty('title') ? this.data.title : 'Multiple Shippers';
    this.shippers = this.data.hasOwnProperty('shipperMap') ? Array.from(this.data.shipperMap) : [];
  }

  ngOnInit(): void {
    if(this.shippers.length === 1){
      this.selectedShipper = this.shippers[0][0];
      this.onSelectShipper();
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onSelectClick() {
    this.dialogRef.close({
      shipper: this.selectedShipper,
      inspector: this.selectedEmployee,
    });
  }

  /**
   * Subscribe to non-Form observables (local and Service)
   */
  initSubscriptions() { }

  /**
   * Subscribe to Form control value and status changes
   */
  initWatchers() { }

  shipperSelected(row) {
    this.selectedShipper = row[0];
  }

  onSelectShipper() {
    this.currentStep = 2;
    this.inspectors = Array.from(this.data.shipperMap.get(this.selectedShipper));
    if(this.inspectors.length === 1){
      this.inspectorSelected(this.inspectors[0]);
      this.onSelectClick();
    }
  }

  inspectorSelected(row) {
    const employee = {
      sicCode: row[1].value.inspectionSic,
      employeeId: row[1].value.inspectorEmployeeId,
      firstName: row[1].value.inspectorFirstNm,
      lastName: row[1].value.inspectorLastNm,
      locationSicName: null,
      jobTitle: null,
      jobCode: null,
      sfEmployeeId: null,
      address1: null,
      cityName: null,
      stateCd: null,
      countryCd: null,
      zip: null,
      isSuggestedSelection: true
    }

    this.selectedEmployee = employee;
  }

  generateInspectorName(inspectionContext) {
    if (inspectionContext) {
      return `${inspectionContext.inspectorFirstNm} ${inspectionContext.inspectorLastNm}`;
    }
    return null;
  }
}
